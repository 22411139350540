<template>
  <section fluid>
    <v-row>
      <v-col>
        <v-breadcrumbs class="pl-0" :items="breads" large></v-breadcrumbs>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        v-if="![27, 25, 18].includes(proceso.id_forma_contratacion)"
      >
        <v-btn
          v-if="proceso.id_seguimiento_proceso === 11"
          @click="VerifyDocsFtn"
          :disabled="disableBtn"
          color="secondary white--text"
          block
        >
          Publicar
        </v-btn>
        <v-tooltip
          bottom
          v-if="
            proceso.id_seguimiento_proceso !== 1 &&
            proceso.id_seguimiento_proceso !== 11 &&
            proceso.id_estado_proceso === 1
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              dark
              v-on="on"
              v-bind="attrs"
              color="secondary "
              @click="
                $router.push({
                  name: 'pac-procesos-etapas',
                  params: { idProceso: proceso.id },
                })
              "
            >
              <v-icon class="mx-2">mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <span>Ver ejecución del proceso</span>
        </v-tooltip>
        <v-btn
          class="my-2 ml-4"
          @click="abrirModalSuspender()"
          v-if="
            haveRoles(['ROLE_UACI', 'ROLE_TITULAR_INSTITUCION']) &&
            proceso.id_estado_proceso === 1 &&
            [2, 3].includes(proceso.id_seguimiento_proceso)
          "
          color="amber lighten-3"
        >
          <v-icon class="mx-1">mdi-pause-circle</v-icon>
          Suspender proceso
        </v-btn>
        <v-btn
          class="my-2 ml-4"
          @click="abrirModalDesierto()"
          v-if="
            haveRoles([
              'ROLE_UACI',
              'ROLE_DIRECCION_COMPRAS',
              'ROLE_TECNICO_DIRECCION_COMPRAS',
            ]) &&
            proceso.id_seguimiento_proceso == 3 &&
            proceso.id_estado_proceso === 1
          "
          color="error white--text"
        >
          <v-icon color="white" class="mx-1">mdi-account-cancel </v-icon>
          Declarar desierto
        </v-btn>
        <v-btn
          class="my-2 ml-4"
          v-if="proceso.id_estado_proceso === 6"
          color="secondary"
          dark
          @click="modalReanudarProc()"
          block
        >
          <v-icon class="mx-1">mdi-play-circle</v-icon>
          Reanudar proceso
        </v-btn>
        <v-btn
          @click="cambioEstadoProceso(11)"
          color="secondary white--text"
          block
          :disabled="!(proceso.id_empleado_tecnico > 0)"
          v-if="
            haveRoles([
              'ROLE_UACI',
              'ROLE_UACI_TECNICO',
              'ROLE_DIRECCION_COMPRAS',
              'ROLE_TECNICO_DIRECCION_COMPRAS',
            ]) && proceso.id_seguimiento_proceso == 1
          "
        >
          Recepción de solicitudes
        </v-btn>
        <p
          class="text-subtitle-1 text-center mb-0"
          v-if="!(proceso.id_empleado_tecnico > 0)"
        >
          Debe completar los datos generales del proceso
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6" v-else>
        <v-btn
          @click="cambioEstadoProceso(11)"
          color="secondary white--text"
          block
          :disabled="!(proceso.id_empleado_tecnico > 0)"
          v-if="
            haveRoles([
              'ROLE_UACI',
              'ROLE_UACI_TECNICO',
              'ROLE_DIRECCION_COMPRAS',
              'ROLE_TECNICO_DIRECCION_COMPRAS',
            ]) && proceso.id_seguimiento_proceso == 1
          "
        >
          Recepción de solicitudes
        </v-btn>
        <v-btn
          color="secondary white--text"
          block
          v-if="
            proceso.id_seguimiento_proceso === 11 &&
            haveRoles([
              'ROLE_UACI',
              'ROLE_UACI_TECNICO',
              'ROLE_DIRECCION_COMPRAS',
              'ROLE_TECNICO_DIRECCION_COMPRAS',
            ])
          "
          :disabled="disableBtn"
          @click="pasarEjecucion"
        >
          Pasar a etapa de ejecución
        </v-btn>
        <v-tooltip
          top
          v-if="
            proceso.id_seguimiento_proceso !== 1 &&
            proceso.id_seguimiento_proceso !== 11 &&
            proceso.id_estado_proceso === 1
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              dark
              v-on="on"
              v-bind="attrs"
              color="secondary "
              @click="
                $router.push({
                  name: 'pac-procesos-etapas',
                  params: { idProceso: proceso.id },
                })
              "
            >
              <v-icon class="mx-2">mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <span>Ver ejecución del proceso</span>
        </v-tooltip>
        <v-btn
          class="my-2 ml-4"
          @click="abrirModalSuspender()"
          v-if="
            haveRoles(['ROLE_UACI', 'ROLE_TITULAR_INSTITUCION']) &&
            proceso.id_estado_proceso === 1 &&
            [12].includes(
              proceso.id_seguimiento_proceso &&
                [18, 27].includes(proceso.id_forma_contratacion)
            )
          "
          color="amber lighten-3"
        >
          <v-icon class="mx-1">mdi-pause-circle</v-icon>
          Suspender proceso
        </v-btn>
        <v-btn
          class="my-2 ml-4"
          @click="abrirModalDesierto()"
          v-if="
            haveRoles([
              'ROLE_UACI',
              'ROLE_DIRECCION_COMPRAS',
              'ROLE_TECNICO_DIRECCION_COMPRAS',
            ]) &&
            proceso.id_seguimiento_proceso == 12 &&
            proceso.id_estado_proceso === 1 &&
            ![18, 27].includes(proceso.id_forma_contratacion)
          "
          color="error white--text"
        >
          <v-icon color="white" class="mx-1">mdi-account-cancel </v-icon>
          Declarar desierto
        </v-btn>
        <v-btn
          class="my-2 ml-4"
          v-if="proceso.id_estado_proceso === 6"
          color="secondary"
          dark
          @click="modalReanudarProc()"
          block
        >
          <v-icon class="mx-1">mdi-play-circle</v-icon>
          Reanudar proceso
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="
        proceso.id_proceso_origen !== null && proceso.id_estado_proceso === 1
      "
      class="col-md-7"
    >
      <v-alert outlined colored-border border="left" prominent>
        <v-icon>mdi-information</v-icon>
        Este proceso ha sido declarado como desierto. El proceso origen
        pertenece al código:
        {{ proceso?.proceso_origen?.codigo_proceso }} haz clic en el botón para
        observarlo:
        <v-btn
          color="secondary"
          dark
          :to="{
            path: `/pac-procesos/etapas/${proceso?.id_proceso_origen}`,
          }"
          >Ver el proceso origen
        </v-btn>
      </v-alert>
    </v-row>
    <p class="text-h5 secondary--text my-4">
      {{ proceso?.nombre_proceso }}
      <v-chip
        class="ml-4 white--text"
        :color="setColorEstado(proceso?.id_estado_proceso)"
        label
        v-if="
          proceso?.id_estado_proceso === 5 ||
          proceso?.id_estado_proceso === 6 ||
          proceso?.id_estado_proceso === 8
        "
      >
        {{ nombreEstado }}
      </v-chip>
    </p>

    <v-btn
      v-if="
        this.haveRoles([
          'ROLE_DIRECCION_COMPRAS',
          'ROLE_TECNICO_DIRECCION_COMPRAS',
        ]) &&
        proceso.id_estado_proceso === 1 &&
        [1, 11, 12].includes(proceso.id_seguimiento_proceso)
      "
      color="warning"
      class="mb-4 white--text"
      @click="$refs.modalCancelarProceso.show = true"
    >
      <v-icon color="white">mdi-delete</v-icon>
      Cancelar proceso
    </v-btn>

    <p
      v-if="cdf?.validacion && !haveRole('ROLE_PROVEEDOR')"
      class="secondary--text body-2 mb-0 mt-4"
    >
      <v-icon color="success">mdi-check-decagram</v-icon>
      Fondo de proceso certificados
    </p>
    <p
      v-if="!cdf?.validacion && !haveRole('ROLE_PROVEEDOR')"
      class="secondary--text body-2 mb-0 mt-4"
    >
      <v-icon color="gray">mdi-alert-decagram</v-icon>
      Se han certificado
      <b>{{
        Intl.NumberFormat("en-US", {
          currency: "USD",
          style: "currency",
          minimumFractionDigits: 2,
          maximumFractionDigits: 8,
        }).format(cdf?.cantidades?.monto_certificado)
      }}</b>
      de
      <b>{{
        Intl.NumberFormat("en-US", {
          currency: "USD",
          style: "currency",
          minimumFractionDigits: 2,
          maximumFractionDigits: 8,
        }).format(cdf?.cantidades?.monto_requerido)
      }}</b>
    </p>

    <v-tabs
      v-model="tab_selected"
      color="secondary"
      show-arrows
      background-color="bgMinsal"
    >
      <v-tab
        v-for="(item, index) in optionsTabFiltered"
        :key="index"
        class="text-no-style secondary--text"
      >
        <v-icon color="secondary"> {{ item.icon }}</v-icon>
        <span class="ml-2" v-text="item.text" />
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab_selected" class="my-7">
      <v-tab-item>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <span class="secondary--text text-h6"
                  >Modalidad de compra:</span
                >
                <br />
                <span class="text-h7">{{
                  proceso.FormaContratacion?.nombre
                }}</span>
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col cols="12" sm="6" md="6">
                <span class="secondary--text text-h6">Inicio del proceso:</span>
                <br />
                <span class="text-h7">{{
                  formatearFecha(proceso.ProcesoCompraPac?.fecha_inicio)
                }}</span>
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col cols="12" sm="6" md="6">
                <span class="secondary--text text-h6"
                  >Finalización del proceso:</span
                >
                <br />
                <span class="text-h7">{{
                  formatearFecha(proceso.ProcesoCompraPac?.fecha_fin)
                }}</span>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                    outlined
                    label="Código interno del proceso"
                    v-model="proceso.codigo_interno"
                    :error-messages="codigoProcesoError"
                    @input="$v.proceso.codigo_interno.$touch()"
                    :disabled="!permiteEdicion"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                    outlined
                    label="Nombre del proceso *"
                    v-model="proceso.nombre_proceso"
                    :error-messages="nombreProcesoError"
                    @input="$v.proceso.nombre_proceso.$touch()"
                    :disabled="!permiteEdicion"
                />
              </v-col>
            </v-row>
            <v-row v-if="proceso.version == 1">
              <v-col cols="12" sm="6" md="6">
                <money-text-field-component
                  ref="monto"
                  label="Suma de mercancía *"
                  v-model="proceso.suma_mercancia"
                  :readonly="!editUACICmp"
                  outlined
                  :error-messages="montoErrors"
                  :disabled="
                    proceso.numero_cdp !== '' && proceso.numero_cdp !== null
                  "
                  @input="$v.proceso.suma_mercancia.$touch()"
                />
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" sm="6" v-if="false">
                <v-autocomplete
                    label="Forma de participación *"
                    outlined
                    v-model="proceso.participacion_publica"
                    :items="[
                    { id: true, nombre: 'Pública' },
                    { id: false, nombre: 'Privada' },
                  ]"
                    item-value="id"
                    item-text="nombre"
                />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                    :items="tecnicos_list"
                    :item-text="item =>`${item.nombre_completo_tecnico} - (${item?.institucion})`"
                    item-value="id_empleado"
                    outlined
                    label="Técnico asignado *"
                    v-model="proceso.id_empleado_tecnico"
                    @blur="$v.proceso.id_empleado_tecnico.$touch()"
                    :error-messages="tecnicoError"
                    @change="guardarDatosProceso"
                    :disabled="!permiteEdicion"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            v-if="haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])"
          >
            <v-row class="my-2" v-if="documentos.length === 0">
              <v-col cols="12" sm="12" md="10">
                <v-alert border="left" type="info" elevation="2" colored-border
                  >Este documento solo se puede subir una vez
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="documentos.length === 0">
              <v-col cols="12" sm="9" md="8">
                <v-file-input
                  label="Expediente de proceso"
                  outlined
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  accept="application/pdf"
                  v-model="expediente"
                  :error-messages="expedienteError"
                  @blur="$v.expediente.$touch()"
                  @input="$v.expediente.$touch()"
                ></v-file-input>
              </v-col>
              <v-col cols="12" sm="3" md="4">
                <v-btn color="secondary" @click="cargar()">Cargar</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="documentos.length > 0">
              <v-col cols="12" sm="12" md="3">
                <v-btn text color="secondary" @click="descargarArchivo">
                  <v-icon color="secondary">mdi-download</v-icon>
                  Descargar expediente de proceso
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col cols="12" sm="4" md="3">
            <v-btn
              block
              outlined
              color="secondary"
              :to="{ name: 'pac-procesos' }"
              >Volver
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-btn block color="secondary" :disabled="!permiteEdicion" @click="guardarDatosProceso"
            >Guardar
            </v-btn
            >
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item v-if="proceso.id_seguimiento_proceso !== 1">
        <doc-proveedor-component v-if="[27].includes(proceso.id_forma_contratacion)" :proceso="proceso"/>
        <PipProcesoComponent 
        v-else
            ref="pipComponent"
            :etapas="proceso?.EtapaPorProcesos"
            @actualizarEtapa="getProceso"
            :permiteEdicion="this.permiteEdicion"
        />

        <v-row class="mt-8">
          <v-col cols="12" sm="2">
            <v-btn
              block
              outlined
              color="secondary"
              :to="{ name: 'pac-procesos' }"
              >Volver
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item v-if="proceso.id_seguimiento_proceso !== 1" eager>
        <container-admin-component />

        <v-row class="mt-8">
          <v-col cols="12" sm="2">
            <v-btn
              block
              outlined
              color="secondary"
              :to="{ name: 'pac-procesos' }"
            >
              Volver
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item v-if="proceso.id_seguimiento_proceso !== 1">
        <SolicitudesAsignadasComponent :permiteEdicion="this.permiteEdicion" :proceso="proceso"/>

        <v-row class="mt-8">
          <v-col cols="12" sm="2">
            <v-btn
              block
              outlined
              color="secondary"
              :to="{ name: 'pac-procesos' }"
              >Volver
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item v-if="proceso.id_seguimiento_proceso !== 1">
        <PEOComponent :permiteEdicion="this.permiteEdicion" />
        <v-row class="mt-8">
          <v-col cols="12" sm="2">
            <v-btn
              block
              outlined
              color="secondary"
              :to="{ name: 'pac-procesos' }"
              >Volver
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item v-if="proceso.id_seguimiento_proceso !== 1">
        <ConfiguracionLotesComponent :permiteEdicion="this.permiteEdicion" />
      </v-tab-item>
      <v-tab-item>
        <doc-proveedor-component :proceso="proceso" />
        <v-row class="mt-8">
          <v-col cols="12" sm="2">
            <v-btn
              block
              outlined
              color="secondary"
              :to="{ name: 'pac-procesos' }"
            >
              Volver
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <ConfirmationDialogComponent
      :show="showConfirmation"
      title="¿Está seguro de publicar este proceso?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Confirmar"
      @close="showConfirmation = false"
      @confirm="publish"
    />

    <v-dialog
      v-model="modalDesierto"
      max-width="60vh"
      @click:outside="cerrar()"
    >
      <v-card>
        <section>
          <v-card-title class="text-h5 secondary--text"
            >Declarar proceso desierto
          </v-card-title>
          <v-card-text>
            <p class="text-h6">
              ¿Desea declarar el siguiente proceso como desierto?
            </p>
            <h4>Proceso</h4>
            <h5>{{ proceso.codigo_proceso }}</h5>
            <h5>{{ proceso.nombre_proceso }}</h5>
            <h5>{{ proceso.FormaContratacion?.nombre }}</h5>
            <h5>
              $
              {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(proceso.suma_mercancia)
              }}
            </h5>
            <v-row class="mt-2">
              <v-col cols="11">
                <v-file-input
                  label="Justificación"
                  outlined
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  append-icon=""
                  accept="application/pdf"
                  v-model="documento"
                  :error-messages="documentoError"
                  @blur="$v.documento.$touch()"
                  @input="$v.documento.$touch()"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row class="mx-1">
              <v-btn outlined color="secondary" class="mr-4" @click="cerrar">
                Volver
              </v-btn>
              <v-btn color="secondary" class="mr-4" @click="declararDesierto()">
                Declarar desierto
              </v-btn>
            </v-row>
          </v-card-text>
        </section>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmation" persistent max-width="45vh">
      <v-card>
        <section>
          <v-card-text>
            <v-row justify="center">
              <v-icon color="success" x-large>mdi-check-circle</v-icon>
            </v-row>
            <v-row justify="center" class="mt-5">
              <span>El proceso ha sido declarado desierto exitosamente</span>
              <span class="text-h6 secondary--text mb-5"
                >¿Desea volver a publicar el proceso?</span
              >
            </v-row>
            <v-row class="mx-2 mt-7 mb-1">
              <v-btn
                outlined
                color="secondary"
                class="mr-7"
                @click="cerrarConfirm"
              >
                No
              </v-btn>
              <v-btn
                color="secondary"
                class="mr-5"
                @click="publicarProcesoDesierto()"
                :loading="modalidades.isLoading"
              >
                Si, deseo publicarlo
              </v-btn>
            </v-row>
          </v-card-text>
        </section>
      </v-card>
    </v-dialog>

    <ProcesoNuevoComponent
      :show="modalPublicar"
      :modalidades="modalidades.data"
      :close="cerrarPublicar"
      :data="proceso"
    />

    <ProcesoSuspenderComponent
      :modal="modalProceso"
      :proceso="proceso"
      @cerrar="cerrarModal"
    />

    <ReanudarProcesoComponent
      :modalRea="modalReanudar"
      @cerrarR="cerrarRea()"
    />

    <!-- Modal para confirmar cancelación de proceso -->
    <ModalCancelarProcesoComponent
      ref="modalCancelarProceso"
      @hideModal="hideModalCancelarProceso"
      @success="getProceso"
    />
  </section>
</template>

<script>
import moment from "moment";
import { mapMutations, mapState } from "vuex";
import { helpers, required } from "vuelidate/lib/validators";
import MoneyTextFieldComponent from "../../components/MoneyTextFieldComponent.vue";
import PipProcesoComponent from "./components/PipProcesoComponent.vue";
import PEOComponent from "./components/ConfiguracionPEOComponent.vue";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";
import SolicitudesAsignadasComponent from "./components/SolicitudesAsignadasComponent.vue";
import ConfiguracionLotesComponent from "./components/ConfiguracionLotesComponent.vue";
import DocProveedorComponent from "./components/DocProveedorComponent.vue";
import ProcesoNuevoComponent from "./components/ProcesoNuevoComponent.vue";
import ProcesoSuspenderComponent from "./components/ProcesoSuspenderComponent.vue";
import ReanudarProcesoComponent from "./components/ReanudarProcesoComponent.vue";
import ModalCancelarProcesoComponent from "./components/ModalCancelarProcesoComponent.vue";
import ContainerAdminComponent from "./components/ContainerAdminComponent.vue";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";
import LegacyValidations from "@/utils/legacy-validations";

const letter = helpers.regex("letter", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 -]*$/);
const priceDecimals = helpers.regex(
  "priceDecimals",
  /^[0-9]+([.][0-9]{1,2})?$/
);

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "ConfiguracionProcesoView",
  components: {
    MoneyTextFieldComponent,
    PipProcesoComponent,
    PEOComponent,
    ConfirmationDialogComponent,
    SolicitudesAsignadasComponent,
    ConfiguracionLotesComponent,
    DocProveedorComponent,
    ProcesoNuevoComponent,
    ProcesoSuspenderComponent,
    ReanudarProcesoComponent,
    ModalCancelarProcesoComponent,
    ContainerAdminComponent,
  },
  data: () => ({
    tab_selected: null,
    proceso: {
      codigo_interno: "",
      nombre_proceso: "",
      id_forma_contratacion: null,
      suma_mercancia: 0,
      id_empleado_tecnico: null,
      participacion_publica: true,
    },
    modalDesierto: false,
    modalidades: createLoadable([]),
    picker: moment().format("YYYY-MM"),
    picker2: null,
    tecnicos_list: [],
    showConfirmation: false,
    documento: null,
    confirmation: false,
    modalPublicar: false,
    modalProceso: false,
    modalReanudar: false,
    expediente: null,
    loading: false,
    documentos: [],
    documento_cargado: null,
    menuInicio: false,
    menuFin: false,
    cdf: {cantidades: null, validacion: null},
    permiteEdicion: false,
  }),
  validations: {
    proceso: {
      codigo_interno: { letter },
      nombre_proceso: { required, letter },
      id_forma_contratacion: { required },
      suma_mercancia: { required, priceDecimals },
      id_empleado_tecnico: { required },
    },
    documento: {
      required,
      fileTypeValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
      fileNameValid,
    },
    expediente: {
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
    picker: { required },
    picker2: { required },
  },
  computed: {
    ...mapState("procesoCompraDoc", ["exist_solicitud"]),
    editUACICmp() {
      return (
        this.haveRole("ROLE_UACI") && this.proceso.id_seguimiento_proceso == 1
      );
    },
    OPTIONS_TAB() {
      if (this.proceso.id_seguimiento_proceso === 1) {
        return [
          {
            icon: "mdi-file-cog",
            text: "General",
          },
        ];
      }

      if ([25].includes(this.proceso.id_forma_contratacion)) {
        return [
          {
            icon: "mdi-file-cog",
            text: "General",
          },
          {
            icon: "mdi-format-list-bulleted",
            text: "Plan de implementación del proceso",
          },
        ];
      }
      if ([18].includes(this.proceso.id_forma_contratacion)) {
        return [
          {
            icon: "mdi-file-cog",
            text: "General",
          },
        ];
      }

      if ([27].includes(this.proceso.id_forma_contratacion)) {
        return [
          {
            icon: "mdi-file-cog",
            text: "General",
          },
          {
            icon: "mdi-file-document-multiple",
            text: "Documentación",
          },
        ]
      }

      if (this.proceso.id_seguimiento_proceso !== 1) {
        return [
          {
            icon: "mdi-file-cog",
            text: "General",
          },
          {
            icon: "mdi-format-list-bulleted",
            text: "Plan de implementación del proceso",
          },
          {
            icon: "mdi-feature-search-outline",
            text: "Documentos de solicitud de oferta",
          },
          {
            icon: "mdi-list-box",
            text: "Solicitudes de compra",
          },
          {
            icon: "mdi-card-account-details-outline",
            text: "PEO / Evaluadores técnicos",
          },
          {
            icon: "mdi-apps-box",
            text: "Configuración de lotes",
          },
          // {
          //   icon: "mdi-file-document-multiple",
          //   text: "Documentos para proveedores",
          // },
        ];
      }
    },
    breads() {
      return [
        {
          text: "Procesos de compra",
          disabled: false,
          href: "/pac-procesos",
        },
        {
          text: `${this.proceso?.codigo_proceso} / ${
            this.proceso?.codigo_interno || ""
          }`,
          disabled: false,
          to: { name: "pac-procesos" },
        },
      ];
    },
    optionsTabFiltered() {
      if (
        this.haveRoles([
          "ROLE_UACI",
          "ROLE_UACI_TECNICO",
          "ROLE_DIRECCION_COMPRAS",
          "ROLE_TECNICO_DIRECCION_COMPRAS",
        ])
      ) {
        return this.OPTIONS_TAB;
      } else
        return this.OPTIONS_TAB.filter(
          (target) => target.text !== "Solicitudes asignadas"
        );
    },
    disableBtn() {
      if (this.proceso.id_forma_contratacion === 18) {
        return this.proceso?.id_empleado_tecnico == null;
      }
      return (
          (this.proceso?.id_empleado_tecnico == null || !this.permiteEdicion )
      );
    },
    codigoProcesoError() {
      const errors = [];
      if (!this.$v.proceso.codigo_interno.$dircerrarty) return errors;
      !this.$v.proceso.codigo_interno.letter &&
        errors.push(
          "El código interno del proceso no puede contener caracteres especiales"
        );
      return errors;
    },
    nombreProcesoError() {
      const errors = [];
      if (!this.$v.proceso.nombre_proceso.$dirty) return errors;
      !this.$v.proceso.nombre_proceso.required &&
        errors.push("El nombre del proceso es requerido");
      !this.$v.proceso.nombre_proceso.letter &&
        errors.push(
          "El nombre del proceso no puede contener caracteres especiales"
        );
      return errors;
    },
    montoErrors() {
      const errors = [];
      if (!this.$v.proceso.suma_mercancia.$dirty) return errors;
      !this.$v.proceso.suma_mercancia.required &&
        errors.push("El campo monto es obligatorio");
      !this.$v.proceso.suma_mercancia.priceDecimals &&
        errors.push(
          "El campo solo admite enteros o decimales y 2 dígitos después del punto decimal"
        );
      return errors;
    },
    modalidadError() {
      const errors = [];
      if (!this.$v.proceso.id_forma_contratacion.$dirty) return errors;
      !this.$v.proceso.id_forma_contratacion.required &&
        errors.push("La modalidad de compra es requerida");
      return errors;
    },
    tecnicoError() {
      const errors = [];
      if (!this.$v.proceso.id_empleado_tecnico.$dirty) return errors;
      !this.$v.proceso.id_empleado_tecnico.required &&
        errors.push("Técnico asignado es requerido");
      return errors;
    },
    documentoError() {
      const errors = [];
      if (!this.$v.documento.$dirty) return errors;
      !this.$v.documento.required && errors.push("Debe de subir un documento");
      !this.$v.documento.fileTypeValid &&
        errors.push("Solo se permiten archivos PDF");
      !this.$v.documento.fileSizeValid &&
        LegacyValidations.pushToErrorsArray(errors);
      !this.$v.documento.fileNameValid &&
        errors.push(
          "El nombre del archivo no debe contener caracteres especiales"
        );
      return errors;
    },
    nombreEstado() {
      const estado = this.proceso.id_estado_proceso;

      if (estado === 5) return "Proceso desierto";
      if (estado === 6) return "Proceso suspendido";
      if (estado === 8) return "Proceso cancelado";
    },
    expedienteError() {
      const errors = [];
      if (!this.$v.expediente.$dirty) return errors;
      !this.$v.expediente.fileTypeValid &&
        errors.push("Solo se permiten archivos PDF");
      !this.$v.expediente.fileSizeValid &&
        LegacyValidations.pushToErrorsArray(errors);
      !this.$v.expediente.fileNameValid &&
        errors.push(
          "El nombre del archivo no debe contener caracteres especiales"
        );
      return errors;
    },
    fechaInicioError() {
      const errors = [];
      if (!this.$v.picker.$dirty) return errors;
      !this.$v.picker.required && errors.push("Debe de seleccionar una fecha");
      return errors;
    },
    fechaFinError() {
      const errors = [];
      if (!this.$v.picker2.$dirty) return errors;
      !this.$v.picker2.required && errors.push("Debe de seleccionar una fecha");
      return errors;
    },
  },
  methods: {
    async pasarEjecucion() {
      const { status, data } =
        await this.services.PacProcesos.procesoCEEjecucion(
          this.$route.params.idProceso
        );
      if (status === 200) {
        this.pushAppMessage({
          message: data.message,
          type: "success",
          show: true,
        });
        location.reload();
      }
    },
    ...mapMutations(["setTitle"]),
    ...mapMutations("procesoCompraDoc", ["setInfoProceso"]),

    VerifyDocsFtn() {
      if (this.exist_solicitud) {
        this.showConfirmation = true;
      } else {
        this.temporalAlert({
          message:
            "No se puede publicar el proceso sin al menos una solicitud de oferta",
          show: true,
          type: "warning",
        });
      }
    },

    setColorEstado(id_estado_proceso) {
      switch (id_estado_proceso) {
        case 4:
          return "white";
        case 5:
          return "error";
        case 6:
          return "amber lighten-3";
        case 8:
          return "info";
        default:
          break;
      }
    },
    fechaFormatted(fecha) {
      if (fecha != null) {
        return moment(fecha).format("MMMM YYYY");
      } else {
        return "";
      }
    },
    async getProceso() {
      const response = await this.services.PacProcesos.getProceso(
        this.$route.params.idProceso
      );

      if (response.status === 200) {
        if (response.data.version != this.$route.meta.version) {
          this.$router.push({
            name: "dashboard",
          });

          this.temporalAlert({
            message:
              "Hay inconsistencias en la versión del proceso, si el problema persiste contacte al administrador",
            show: true,
            type: "warning",
          });
        }

        if (response.data.id_estado_proceso === 8) {
          this.$router.push({
            name: "pac-procesos",
          });

          this.temporalAlert({
            message:
              "El proceso al que se desea acceder ha sido cancelado anteriormente",
            show: true,
            type: "warning",
          });
        }

        this.proceso = response.data;
        this.proceso.suma_mercancia = Number(response.data.suma_mercancia);
        this.$refs?.monto?.updateValue(this.proceso.suma_mercancia);
        this.picker = moment(response.data.fecha_inicio_proceso).format(
          "YYYY-MM"
        );
        this.picker2 = moment(response.data.fecha_contratacion).format(
          "YYYY-MM"
        );
        this.proceso.id_empleado_tecnico = response.data.id_empleado_tecnico;
        this.proceso.id_seguimiento_proceso =
          response.data.id_seguimiento_proceso;

        this.setInfoProceso(response.data);
        //
        let response2 =
          await this.services.PacProcesos.obtenerDocumentosProceso(
            this.$route.params.idProceso,
            5000
          );
        if (response2?.status === 200) {
          this.documentos = response2?.data;
          this.documento_cargado = this.documentos[0]?.ruta_documento;
        }
        //
      }
    },
    async fetchModalidades() {
      toggleLoadable(this.modalidades);
      const response = await this.services.Paac.getModalidades({
        compra_tradicional: true,
      });

      if (response.status === 200) {
        setLoadableResponse(this.modalidades, {
          data: response.data.forma_contratacion,
        });
        return;
      }

      setLoadableResponse(this.modalidades, {
        error: "Error al cargar las modalidades",
      });
    },
    async guardarDatosProceso() {
      let params = {
        codigo_interno: this.proceso.codigo_interno,
        nombre_proceso: this.proceso.nombre_proceso,
        suma_mercancia: Number(this.proceso.suma_mercancia),
        fecha_inicio: this.moment(this.picker)
          .startOf("month")
          .format("YYYY-MM-DD hh:mm"),
        fecha_fin: this.moment(this.picker2)
          .endOf("month")
          .format("YYYY-MM-DD hh:mm"),
        id_empleado_tecnico: this.proceso.id_empleado_tecnico,
        participacion_publica: this.proceso.participacion_publica,
      };

      const { status } = await this.services.PacProcesos.actualizarProceso(
        this.$route.params.idProceso,
        params
      );
      if (status === 200 || status === 201) {
        setTimeout(() => {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Proceso de compra actualizado",
          });
        }, 1500);
      }
    },
    async getTecnicos() {
      const { status, data } =
        await this.services.Paac.getTecnicosProcesoCompra(
          this.$route.params.idProceso
        );

      if (status == 200) {
        this.tecnicos_list = data;
      }
    },
    async publish() {
      const response = await this.services.PacProcesos.publicarProceso(
        this.$route.params.idProceso
      );

      if (response?.status == 200) {
        setTimeout(() => {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Proceso de compra publicado con éxito",
          });
        }, 1500);

        location.reload();
      }
    },
    async cambioEstadoProceso(id_siguiente_estado) {
      const { status } =
        await this.services.PacProcesos.cambioEstadoProcesoCompra(
          this.$route.params.idProceso,
          id_siguiente_estado
        );

      if (status == 200) {
        if (id_siguiente_estado === 11) location.reload();
        if (id_siguiente_estado !== 11) this.$router.replace("/pac-procesos");
      }
    },
    abrirModalDesierto() {
      this.modalDesierto = true;
    },
    abrirModalSuspender() {
      this.modalProceso = true;
    },
    cerrarModal() {
      this.modalProceso = false;
      this.getProceso();
    },
    cerrar() {
      this.modalDesierto = false;
      this.documento = null;
    },
    async declararDesierto() {
      if (this.documento) {
        let formData = new FormData();
        formData.append("documento", this.documento);
        const response = await this.services.PacProcesos.putProcesoDesierto(
          this.$route.params.idProceso,
          formData
        );

        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          this.cerrar();
          // this.getProceso()
          this.confirmation = true;
        }
      } else {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "Debe cargar un documento",
        });
      }
    },
    cerrarConfirm() {
      this.confirmation = false;
      this.getProceso();
    },
    async publicarProcesoDesierto() {
      await this.fetchModalidades();
      this.modalPublicar = true;
    },
    cerrarPublicar() {
      this.cerrarConfirm();
      this.modalPublicar = false;
    },
    modalReanudarProc() {
      this.modalReanudar = true;
    },
    cerrarRea() {
      this.modalReanudar = false;
      this.getProceso();
    },
    async cargar() {
      this.$v.$touch();
      const formData = new FormData();
      formData.append("documento", this.expediente);
      formData.append("nombre", "Expediente de proceso");
      formData.append("id_proceso", this.$route.params.idProceso);
      formData.append("id_tipo_documento", 5000);
      formData.append("folder", "expediente_proceso");
      const response = await this.services.PacProcesos.guardarDocumentosProceso(
        formData
      ).catch(() => {
        if (this.$v.$invalid) {
          if (this.expediente === null) {
            this.temporalAlert({
              show: true,
              type: "error",
              message: "Debe de subir un documento",
            });
          }
        }
      });
      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Documento cargado exitosamente",
        });
        this.getProceso();
        this.expediente = null;
        this.$v.$reset();
      }
    },
    async descargarArchivo() {
      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: this.documento_cargado,
        });
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], this.documentos[0].nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();
    },

    hideModalCancelarProceso() {
      this.$refs.modalCancelarProceso.show = false;
    },

    async verificarCdfProceso() {
      const response = await this.services.PacProcesos.verificarCdfProceso(
        this.$route.params.idProceso
      );
      if (response.status === 200) {
        this.cdf.cantidades = response.data;

        if ( response.data?.monto_certificado !== null && response.data?.monto_requerido !== null) {
          this.cdf.validacion =
            response.data?.monto_certificado >= response.data?.monto_requerido;
        }
      }
    },

    formatearFecha(fecha) {
      return fecha ? moment(fecha, "YYYY-MM-DD").format("DD-MM-YYYY") : "-";
    },
    
    verificarRol() {
      if (this.haveRoles(['ROLE_UACI_TECNICO', 'ROLE_UACI'])) {
        this.permiteEdicion = true;
      };
    },

  },
  watch: {
    picker() {
      if (!this.$route.params.idProceso) this.picker2 = this.picker;
    },
  },
  async created() {
    await this.getProceso();
    this.setTitle(
      `COMPRASAL - ${this.proceso?.nombre_proceso} - ${this.proceso?.codigo_interno}`
    );
    this.getTecnicos();
    this.verificarCdfProceso();
    this.verificarRol();
  },
  beforeDestroy() {
    this.setTitle("COMPRASAL");
  },
};
</script>
